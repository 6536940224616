import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import ProductContent from "../components/ProductContent";

const AcDiagnosticToolsAndMaintenanceSolutionsPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <ProductContent
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    );
};

AcDiagnosticToolsAndMaintenanceSolutionsPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default AcDiagnosticToolsAndMaintenanceSolutionsPage;

export const AcDiagnosticToolsAndMaintenanceSolutionsPageQuery = graphql`
  query AcDiagnosticToolsAndMaintenanceSolutionsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
